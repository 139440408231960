import Vue from 'vue';
import router from '../../router';
const clone = require("clone");
const Axios = require("axios");
import Compressor from 'compressorjs';

export default {
  state: {
    page: localStorage.getItem("page") || 1,
    search: localStorage.getItem("search") || '{}',
    next_product: 0,
    previous_product: 0,
    pages: 0,
    loading: false,
    newData: {
      products: [],
      product: {},
    },
    oldData: {
      products: [],
      product: {},
    },
  },
  mutations: {
    setProducts(state, result) {
      state.pages = result.pages;
      state.newData.products = result.products;
      state.oldData.products = clone(result.products);
    },
    setProduct(state, product) {
      state.newData.product = product;
      state.oldData.product = clone(product);
    },
    setProductWebsiteOptions(state, payload) {
      // Create a website_options object for the product if not already present
      if (!('website_options' in state.newData.product)) {
        Vue.set(state.newData.product, "website_options", {});
        Vue.set(state.oldData.product, "website_options", {});
      }
      Vue.set(state.newData.product.website_options, `${payload.website_id}`, payload.options);
      Vue.set(state.oldData.product.website_options, `${payload.website_id}`, clone(payload.options));
    },
    saveWebsiteOptions(state, website_id) {
      state.oldData.product.website_options[website_id] = clone(state.newData.product.website_options[website_id]);
    },
    editProductWebsiteOptions(state, payload) {
      state.oldData.product.website_options[payload.site.website_id] = clone(payload.info);
    },
    "socket.update_website_options"(state, payload) {
      if (payload.created_tags === undefined) {
        payload.created_tags = [];
      }
      let website_id = Array.isArray(payload) ? payload[0].website_id : payload.website_id
      if (state.newData.product.website_options !== undefined) {
        state.newData.product.website_options[website_id] = payload;
        state.oldData.product.website_options[website_id] = clone(payload);
      }
    },
    setProductVariantType(state, variant_type) {
      state.newData.product.variant_type = variant_type;
    },
    addProduct(state, product) {
      state.newData.products.push(product);
      state.oldData.products.push(clone(product));
    },
    editProduct(state) {
      state.oldData.product = clone(state.newData.product);
      state.oldData.products = clone(state.newData.products);
    },
    removeProduct(state, product_id) {
      state.newData.products.splice(state.newData.products.findIndex(x => x.product_id === product_id), 1);
      state.oldData.products.splice(state.oldData.products.findIndex(x => x.product_id === product_id), 1);
    },
    discardProductChanges(state) {
      state.newData.product = clone(state.oldData.product);
      state.newData.products = clone(state.oldData.products);
    },
    setPage(state, page) {
      state.page = page;
      localStorage.setItem("page", page);
    },
    setSearch(state, search) {
      state.search = JSON.stringify(search);
      localStorage.setItem("search", JSON.stringify(search));
    },
    setNewProductImages(state, images) {
      state.newData.product.images = images;
    },
    addProductImages(state, images) {
      for (var image of images) {
        state.newData.product.images.push(image);
        state.oldData.product.images.push(clone(image));
      }
    },
    editProductImages(state, images) {
      state.newData.product.images = images;
      state.oldData.product.images = clone(images);
    },
    removeProductImage(state, index) {
      Vue.set(state.newData.product.images[index], 'is_deleted', true);
    },
    setVariantImage(state, payload) {
      state.newData.product.images[state.newData.product.images.findIndex(x => x.image_id === payload.image_id)].variant_id = payload.variant_id;
      state.oldData.product.images[state.oldData.product.images.findIndex(x => x.image_id === payload.image_id)].variant_id = payload.variant_id;
    },
    addVariant(state, variant) {
      state.newData.product.variants.push(variant);
    },
    editVariant(state, variant) {
      let new_variant = {
        variant_id: variant.variant_id,
        product_id: variant.product_id,
        sku: variant.sku,
        barcode: variant.barcode,
        variant_name: variant.variant_name,
        variant_type: variant.variant_type,
        prices: variant.prices
      }
      Vue.set(state.newData.product.variants, state.newData.product.variants.findIndex(x => x.variant_id === variant.variant_id), new_variant);
      Vue.set(state.oldData.product.variants, state.newData.product.variants.findIndex(x => x.variant_id === variant.variant_id), clone(new_variant));
    },
    removeVariant(state, variant_index) {
      let variant = state.newData.product.variants[variant_index];
      // If the variant exists and is not already deleted
      if ("variant_id" in variant && !("is_deleted" in variant)) {
        Vue.set(variant, 'is_deleted', true);
      } else {
        state.newData.product.variants.splice(variant_index, 1);
        state.oldData.product.variants.splice(variant_index, 1);
      }
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setNextProduct(state, product_id) {
      state.next_product = product_id ? product_id : 0;
    },
    setPreviousProduct(state, product_id) {
      state.previous_product = product_id ? product_id : 0;
    }
  },
  actions: {
    getProducts({ getters, state, commit }) {
      commit("setLoading", true);
      // Initialize base URL for search
      let url = `${process.env.VUE_APP_API_BASE_URL}/products?page=${state.page}`;
      let new_search = {};

      // Dynamically build URL query string
      if (getters.getSearch.name) {
        url += `&name=${getters.getSearch.name}`;
        new_search.name = getters.getSearch.name;
      }
      if (getters.getSearch.sku) {
        url += `&sku=${getters.getSearch.sku}`;
        new_search.sku = getters.getSearch.sku;
      }
      if (getters.getSearch.brand) {
        url += `&brand=${getters.getSearch.brand}`;
        new_search.brand = getters.getSearch.brand;
      }
      if (getters.getSearch.variant_type) {
        url += `&variant_type=${getters.getSearch.variant_type}`;
        new_search.variant_type = getters.getSearch.variant_type;
      }
      if (getters.getSearch.sort) {
        new_search.sort = getters.getSearch.sort;
        new_search.sort_by = getters.getSearch.sort_by;
        new_search.sort_desc = getters.getSearch.sort_desc;
        url += `&sort=${getters.getSearch.sort}`;
      }
      if (getters.getSearch.website) {
        new_search.website = getters.getSearch.website;
        url += `&website=${getters.getSearch.website}`;
        if (getters.getSearch.status) {
          new_search.status = getters.getSearch.status;
          url += `&status=${getters.getSearch.status}`;
        }
      }
      commit("setSearch", new_search);
      Axios.get(url)
        .then(result => {
          commit("setProducts", result.data);
          commit("setLoading", false);
          // If current page is greater than pages, change page
          if (parseInt(state.page) > result.data.pages) {
            commit("setPage", 1);
          }
        })
        .catch(err => {
          console.log(err);
          commit("setLoading", false);
        });
    },
    async getProduct({ commit }, product_id) {
      // Nullify product for skeleton loaders
      // and to allow text editor to never get
      // wrong text prop
      commit("setProduct", {});
      await Axios.get(`${process.env.VUE_APP_API_BASE_URL}/products/${product_id}`)
        .then(result => {
          commit("setProduct", result.data.product);
          commit("setNextProduct", result.data.next_product);
          commit("setPreviousProduct", result.data.previous_product);
          commit("setPage", result.data.current_page);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getProductWebsiteOptions({ commit }, payload) {
      // Define which endpoint to get from depending on website type
      let websiteProductType = (payload.website.type === "B2BWave" ? "b2bwaveproduct" : "shopifyproduct")

      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}/${websiteProductType}/${payload.website.website_id}`)
        .then(result => {
          // If fetching Shopify data, initialize array for Created Tags
          if (payload.website.type === "Shopify") {
            result.data["created_tags"] = [];
          }
          commit("setProductWebsiteOptions", { website_id: payload.website.website_id, options: result.data });
        })
        .catch(err => {
          console.log(err);
        });
    },
    uploadProduct({ rootState, commit, dispatch }, payload) {
      dispatch("performingUpdate", true);
      
      let product_payload = {
        name: payload.name,
        description: payload.description,
        brand_id: payload.brand,
        variant_type: payload.variant_type
      };
      return Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products`, product_payload)
        .then(result => {
          // Get the returned ID of the new product
          payload["product_id"] = result.data.product_id;

          // Iterate through each variant and upload
          return dispatch("uploadProductVariants", payload);
        })
        .then(() => {
          // If images provided, upload them
          if (payload.images.length > 0) {
            return dispatch("uploadProductImages", { images: payload.images, settings: payload.image_settings, product_id: payload.product_id });
          }
        })
        .then(images => {
          // If images were provided, add the hero image to the payload, otherwise add no image
          payload["image_url"] = (images ? images[0].image_url : process.env.VUE_APP_NO_IMAGE_IMG);
          
          // Commit the new product to the store
          let brand_name = rootState.brands.brands.find(x => x.brand_id === payload.brand).name;
          commit("addProduct", {product_id: payload.product_id, name: payload.name, brand_name: brand_name, variant_type: payload.variant_type, variants: payload.variants.length, image_url: payload.image_url});
        })
        .then(() => {
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", { text: `${payload.name} Added Successfully!`, color: "success", timeout: 2500 });
          return payload.product_id;
        })
        .catch(err => {
          console.log(err);
          dispatch("performingUpdate", false);
        });
    },
    async uploadProductVariants(_, payload) {
      for (var i in payload.variants) {
        let variant_payload = {
          variant_name: payload.variants[i].name,
          sku: payload.variants[i].sku,
          barcode: payload.variants[i].barcode
        };
        await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}/variants`, variant_payload)
          .catch(err => {
            console.log(err);
            throw new Error(err);
          });
      }
    },
    async uploadProductImages({ dispatch }, payload) {
      // Pre-process images and compress if required
      let { images_payload, images_errors } = await dispatch("buildImagesPayload", payload);
      // Show image errors
      if (images_errors.length > 0) {
        dispatch('showSnackbar', { text: `${images_errors.length} Image${images_errors.length === 1 ? '' : 's'} Failed to Upload!`, color: "error", timeout: 2500});
      }
      return await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}/images`, images_payload)
        .then(result => {
          // Return created images
          return result.data;
        })
        .catch(err => {
          console.log(err);
          throw new Error(err);
        });
    },
    updateProduct({ commit, dispatch }, payload) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}`, payload)
        .then(() => {
          // Update the old data to match the new
          commit("editProduct");
          dispatch("showSnackbar", {text: "Product Updated Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
        });
    },
    async updateVariants({ commit, dispatch, getters }, payload) {
      for (var i in payload) {
        await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${getters.getProduct.product_id}/variants/${payload[i].variant_id}`, payload[i])
          .catch(err => {
            console.log(err);
          });
      }
      // Update the old data to match the new
      commit("editProduct");
      dispatch("showSnackbar", {text: "Variants Updated Successfully!", color: "success", timeout: 2500});
    },
    async uploadVariants({ commit, dispatch }, payload) {
      for (var i in payload) {
        await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${payload[i].product_id}/variants`, payload[i])
          .then((result) => {
            // Add the new variant ID to the variant
            payload[i]["variant_id"] = result.data.variant_id;
            commit("editVariant", payload[i]);
          })
          .catch(err => {
            console.log(err);
          });
      }
      dispatch("showSnackbar", {text: "Variants Added Successfully!", color: "success", timeout: 2500});
    },
    async deleteVariants({ commit, dispatch }, payload) {
      for (var  i = payload.length -1; i >= 0; i--) {
        await Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/products/${payload[i].product_id}/variants/${payload[i].variant_id}`)
          .then(() => {
            commit("removeVariant", payload[i].index);
          })
          .catch(err => {
            console.log(err);
          });
      }
      dispatch("showSnackbar", {text: "Variants Removed Successfully!", color: "success", timeout: 2500});
    },
    updateImages({ commit, dispatch, getters }, payload) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${getters.getProduct.product_id}/images`, payload)
        .then(() => {
          // Then update the old images to match the new
          commit("editProductImages", payload);
          dispatch("showSnackbar", {text: "Images Updated Successfully!", color: "success", timeout: 2500});
        })
        .catch(err => {
          console.log(err);
          throw new Error(err);
        });
    },
    async uploadImages({ commit, dispatch, getters }, payload) {
      let snackbar_id = await dispatch("showLongSnackbar", {text: `Uploading Images!`, color: "success"});

      // Pre-process images and compress if required
      let { images_payload, images_errors } = await dispatch("buildImagesPayload", payload);

      // Handle errors
      if (images_errors.length > 0) {
        dispatch('showSnackbar', { text: `${images_errors.length} Image${images_errors.length === 1 ? '' : 's'} Failed to Upload!`, color: "error", timeout: 2500});
        if (payload.images.length === images_errors.length) {
          commit("removeSnackbar", snackbar_id);
          return;
        }
      }
      Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${getters.getProduct.product_id}/images`, images_payload)
        .then(result => {
          // Add the new images to the state
          commit("addProductImages", result.data);
          dispatch("hideLongSnackbar", { text: `${result.data.length} Image${result.data.length === 1 ? '' : 's'} Uploaded Successfully!`, color: "success", timeout: 2500, id: snackbar_id});
        })
        .catch(err => {
          dispatch("hideLongSnackbar", { text: `Image Upload Failed!`, color: "error", timeout: 2500, id: snackbar_id});
          console.log(err);
        });
    },
    async uploadVariantImages({ commit, dispatch, getters }, payload) {
      let snackbar_id = await dispatch("showLongSnackbar", {text: `Uploading Images!`, color: "success"});

      // Pre-process images and compress if required
      let { images_payload, images_errors } = await dispatch("buildImagesPayload", payload);

      // Handle errors
      if (images_errors.length > 0) {
        dispatch('showSnackbar', { text: `${images_errors.length} Image${images_errors.length === 1 ? '' : 's'} Failed to Upload!`, color: "error", timeout: 2500});
        if (payload.images.length === images_errors.length) {
          commit("removeSnackbar", snackbar_id);
          return;
        }
      }
      Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${getters.getProduct.product_id}/variants/${payload.variant_id}/images`, images_payload)
        .then(result => {
          // Add the new images to the state
          commit("addProductImages", result.data);
          dispatch("hideLongSnackbar", { text: `${result.data.length} Image${result.data.length === 1 ? '' : 's'} Uploaded Successfully!`, color: "success", timeout: 2500, id: snackbar_id});
        })
        .catch(err => {
          dispatch("hideLongSnackbar", { text: `Image Upload Failed!`, color: "error", timeout: 2500, id: snackbar_id});
          console.log(err);
        });
    },
    async deleteImages({ getters }, payload) {
      for (var i in payload) {
        await Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/products/${getters.getProduct.product_id}/images/${encodeURIComponent(payload[i].image_name)}`)
          .catch(err => {
            console.log(err);
          });
      }
    },
    async updateWebsiteProducts({ commit, dispatch }, payload) {
      // Iterate through each website product to be updated
      for (var product of payload) {
        // Switch on website type to update product
        switch (product.website.type) {
          case 'B2BWave':
            // Iterate through each B2BWave product variant
            for await (var website_product of product.changes) {
              try {
                let siteDetails = {
                  categories: website_product.categories,
                  available: website_product.available,
                  name: website_product.name,
                  local_description: website_product.description,
                  local_meta_description: website_product.meta_description,
                  use_custom_fields: website_product.use_custom_fields
                };
                await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/variants/${website_product.variant_id}/b2bwaveproduct/${product.website.website_id}`, siteDetails);
              } catch (err) {
                console.log(err);
              }
            }
            break;
          case 'Shopify':
            try {
              await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${product.product_id}/shopifyproduct/${product.website.website_id}`, product.changes)
            } catch (err) {
              console.log(err);
            }
            break;
        }

        // After the updates have been performed, update the old data
        commit("saveWebsiteOptions", product.website.website_id);

        dispatch("showSnackbar", {text: "Webite Options Updated Successfully!", color: "success", timeout: 5000});
      }
    },
    async updateWebsiteVariants({ commit, dispatch }, payload) {
      // Iterate through each website variant to be updated
      for (var variant of payload) {
        // Switch on website type to update variant
        switch (variant.website.type) {
          case 'Shopify':
            try {
              await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${variant.product_id}/variants/${variant.variant_id}/shopifyproduct/${variant.website.website_id}`, variant.changes)
            } catch (err) {
              console.log(err);
            }
            break;
        }

        // After the updates have been performed, update the old data
        commit("saveWebsiteOptions", variant.website.website_id);

        dispatch("showSnackbar", {text: "Webite Variant Updated Successfully!", color: "success", timeout: 5000});
      }
    },
    async uploadWebsiteTags(_, payload) {
      // Iterate through each website's tags to be created
      for (var tag of payload) {
        try {
          await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/websites/${tag.website.website_id}/tags`, tag.tags)
        } catch (err) {
          console.log(err);
        }
      }
    },
    async deleteProductMetafields({ commit, dispatch }, payload) {
      // Iterate through each website's product metafields to be deleted
      for (var website_metafields of payload) {
        // Iterate through each individual product metafield
        for (var metafield_id of website_metafields.metafields) {
          try {
            await Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/products/${website_metafields.product_id}/shopifyproduct/${website_metafields.website.website_id}/metafields/${metafield_id}`)
          } catch (err) {
            console.log(err);
          }
        }

        // After the updates have been performed, update the old data
        commit("saveWebsiteOptions", website_metafields.website.website_id);

        dispatch("showSnackbar", {text: "Webite Product Metafields Deleted Successfully!", color: "success", timeout: 5000});
      }
    },
    async deleteVariantMetafields({ commit, dispatch }, payload) {
      // Iterate through each website's variant metafields to be deleted
      for (var website_metafields of payload) {
        // Iterate through each individual variant metafield
        for (var metafield_id of website_metafields.metafields) {
          try {
            await Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/products/${website_metafields.product_id}/variants/${website_metafields.variant_id}/shopifyproduct/${website_metafields.website.website_id}/metafields/${metafield_id}`)
          } catch (err) {
            console.log(err);
          }
        }

        // After the updates have been performed, update the old data
        commit("saveWebsiteOptions", website_metafields.website.website_id);

        dispatch("showSnackbar", {text: "Webite Variant Metafields Deleted Successfully!", color: "success", timeout: 5000});
      }
    },
    deleteProduct({ commit, dispatch, getters }, payload) {
      dispatch("performingUpdate", true);
      Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}`)
        .then(() => {
          commit("removeProduct", payload.product_id);
        })
        .then(() => {
          router.push({ path: '/products', query: { page: getters.getPage }}).catch(() => {});
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", { text: `${payload.name} Deleted Successfully!`, color: "success", timeout: 2500 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    duplicateProduct({ dispatch }, payload) {
      dispatch("performingUpdate", true);
      Axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}/duplicate`)
        .then(result => {
          router.push({ path: `/products/${result.data.product_id}` }).catch(() => {});
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", { text: `${payload.name} Duplicated Successfully!`, color: "success", timeout: 2500 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    discardProductChanges({ commit, dispatch }) {
      commit("discardProductChanges");
      dispatch("showSnackbar", {text: "Changes Discarded Successfully!", color: "success", timeout: 2500});
    },
    async publishProduct({ commit, dispatch }, payload) {
      let snackbar_id = await dispatch("showLongSnackbar", {text: `Publishing ${payload.product_name} to ${payload.website_name}!`, color: "success"});
      return await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}/websites/${payload.website_id}/publish`, payload.data)
        .then(response => {
          // Remove the long snackbar
          commit("removeSnackbar", snackbar_id);
          // Iterate through each of the results and provide feedback
          for (var result of response.data.results) {
            dispatch('showSnackbar', {text: `${payload.website_name} - ${result.message}`, color: result.result, timeout: result.result === "error" ? -1 : 10000});
          }
        })
        .catch(err => {
          commit("removeSnackbar", snackbar_id);
          dispatch("showSnackbar", {text: "There was an error publishing your product!", color: "error", timeout: -1});
          console.log(err);
        });
    },
    async publishProducts({ dispatch }, payload) {
      return await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/publish`, payload)
        .then(() => {
          document.getElementById('notificationsBell').click();
          dispatch("showSnackbar", {text: `Product publish in progress!`, color: "success", timeout: 5000});
        })
        .catch(err => {
          dispatch("showSnackbar", {text: "There was an error publishing your product!", color: "error", timeout: -1});
          console.log(err);
        });
    },
    updateProductLastUpdated(_, product_id) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${product_id}/last_updated`)
        .catch(err => {
          console.log(err);
        });
    },
    async buildImagesPayload(_, payload) {
      let images_payload = new FormData();
      let images_errors = [];
      let promises = [];
      // If using image compression settings, process
      if (payload.settings.use_settings === true) {
        payload.images.forEach(image => {
          promises.push(new Promise((resolve, reject) => {
            new Compressor(image.file, {
              strict: false,
              quality: payload.settings.quality,
              width: payload.settings.width,
              height: payload.settings.height,
              resize: payload.settings.resize,
              mimeType: payload.settings.mime_type,
              beforeDraw(context, canvas) {
                context.fillStyle = payload.settings.fill_background ? payload.settings.background_color : null;
                context.fillRect(0, 0, canvas.width, canvas.height);
              },
              success(result) {
                resolve(new File([result], result.name));
              },
              error(err) { 
                reject(err);
              }
            })
          }))
        });
        let images = await Promise.allSettled(promises);
        for (var image of images) {
          if (image.status === 'fulfilled') {
            images_payload.append("photo", image.value);
          } else if (image.status === 'rejected') {
            images_errors.push(image.reason);
          }
        }
      }
      // Otherwise, push raw images to payload
      else {
        payload.images.forEach(image => {
          if (image.size <= 10485760) {
            images_payload.append("photo", image.file);
          } else {
            images_errors.push(image);
          }
        });
      }
      return { images_payload, images_errors };
    },
    async syncProduct({ dispatch }, payload) {
      let snackbar_id = await dispatch("showLongSnackbar", {text: `Syncing ${payload.product_name} on ${payload.website_name}!`, color: "success"});
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${payload.product_id}/${payload.website_type === 'Shopify' ? 'shopifyproduct' : 'b2bwaveproduct'}/${payload.website_id}/sync`)
        .then(() => {
          dispatch("hideLongSnackbar", { text: `${payload.product_name} Synced Successfully!`, color: "success", timeout: 2500, id: snackbar_id});
        })
        .catch(err => {
          console.log(err);
        });
    },
    "socket.refetch_website_options"({ dispatch, getters }, payload) {
      // Only needs to refetch if website options is defined
      // and if the current product matches the payload's ID
      // Otherwise, website options have not been fetched yet
      // and will be fetched upon first navigation in the FE
      if (getters.getProduct.product_id === payload.product_id && getters.getProduct.website_options !== undefined) {
        dispatch('getProductWebsiteOptions', payload);
      }
    },
  },
  getters: {
    getProducts: state => state.newData.products,
    getPage: state => parseInt(state.page),
    getPages: state => state.pages,
    getNextProduct: state => state.next_product,
    getPreviousProduct: state => state.previous_product,
    getSearch: state => JSON.parse(state.search),
    getProduct: state => state.newData.product,
    getLoading: state => state.loading,
  },
};
