import Vue from 'vue';
import router from '../../router';
const clone = require("clone");
const Axios = require("axios");

export default {
  state: {
    websites: [],
    newData: {
      website: {},
    },
    oldData: {
      website: {},
    }
  },
  mutations: {
    setWebsites(state, websites) {
      state.websites = websites;
    },
    setWebsite(state, website) {
      state.newData.website = website;
      state.oldData.website = clone(website);
    },
    setWebsiteOptions(state, payload) {
      Vue.set(state.newData.website, "options", payload.options);
      Vue.set(state.oldData.website, "options", payload.options);
      // If there is atleast one website assume we are using function
      // from state where websites have been set
      if (state.websites.length !== 0) {
        let website = state.websites.find(x => x.website_id === payload.website_id);
        // Create a options object for the website if not already present
        if (!('options' in website)) {
          Vue.set(website, "options", {});
        }
        Vue.set(website, "options", payload.options);
      }
    },
    setWebsitePriceLists(state, payload) {
      if (!('pricelists' in state.newData.website)) {
        Vue.set(state.newData.website, "pricelists", []);
      }
      state.newData.website.pricelists = payload;
      state.oldData.website = clone(state.newData.website);
    },
    addWebsite(state, website) {
      state.websites.push(website);
    },
    editWebsite(state, website) {
      for (var key in website) {
        // Update the old website value to the new value
        state.oldData.website[key] = website[key];
      }
    },
    editWebsiteSettings(state, website_settings) {
      delete website_settings.website_id;
      for (var key in website_settings) {
        // Update the old website value to the new value
        state.oldData.website.settings[key] = website_settings[key];
      }
    },
    editWebsitePriceLists(state) {
      state.oldData.website = clone(state.newData.website);
    },
    removeWebsite(state, website_id) {
      state.websites.splice(state.websites.findIndex(x => x.website_id === website_id), 1);
    },
    discardWebsiteChanges(state) {
      state.newData.website = clone(state.oldData.website);
    }
  },
  actions: {
    setWebsitesOrder({ commit }, websites) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites`, websites)
      .then(() => {
        commit("setWebsites", websites);
      })
      .catch(err => {
        console.log(err);
      });
    },
    getWebsites({ commit }) {
      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites`)
        .then(result => {
          commit("setWebsites", result.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getWebsite({ commit }, website_id) {
      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites/${website_id}`)
        .then(result => {
          commit("setWebsite", result.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getWebsitePriceLists({ commit }, website_id) {
      return Axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites/${website_id}/pricelists`)
        .then(result => {
          commit("setWebsitePriceLists", result.data)
        })
        .catch(err => {
          console.log(err);
        });
    },
    getWebsiteOptions({ commit }, payload) {
      // Define which endpoint to get from depending on website type
      let websiteType = (payload.website.type === "B2BWave" ? "categories" : "tags")
      Axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website.website_id}/${websiteType}`)
        .then(result => {
          commit("setWebsiteOptions", { website_id: payload.website.website_id, options: result.data });
        })
        .catch(err => {
          console.log(err);
        });
    },
    syncWebsite({ dispatch }, payload) {
      let settings = clone(payload.settings);
      dispatch('showSnackbar', {text: `${payload.website_name} - Syncing ${settings.concat(settings.splice(-2, 2).join(' and ')).join(', ')}`, color: 'success', timeout: 5000});
      return Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website_id}/sync`, payload)
        .then(() => {
          document.getElementById('notificationsBell').click();
        })
        .catch(err => {
          console.log(err);
        });
    },
    uploadWebsite({ commit, dispatch }, payload, sync_website) {
      dispatch("performingUpdate", true);
      return Axios.post(`${process.env.VUE_APP_API_BASE_URL}/websites`, payload)
        .then(result => {
          // Get the returned ID of the new website
          payload["website_id"] = result.data.website_id;

          // Upload the website settings
          return dispatch("uploadWebsiteSettings", payload);
        })
        .then(() => {
          // Commit the new website to the store
          commit("addWebsite", payload);

          // Sync the new website
          if (sync_website) {
            dispatch("syncWebsite", { website_id: payload.website_id, website_name: payload.name});
          }
        })
        .then(() => {
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", { text: `${payload.name} Added Successfully!`, color: "success", timeout: 2500 });
          return payload.website_id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async uploadWebsiteSettings(_, payload) {
      await Axios.post(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website_id}/settings`, payload)
        .catch(err => {
          console.log(err);
        })
    },
    updateWebsite({ commit, dispatch }, payload) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website_id}`, payload)
        .then(() => {
          // Update the old data to match the new
          commit("editWebsite", payload);
          dispatch("showSnackbar", { text: "Website Updated Successfully!", color: "success", timeout: 2500 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateWebsiteSettings({ commit, dispatch, getters }, { payload, website_type }) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites/${getters.getWebsite.website_id}/settings?type=${website_type}`, payload)
        .then(() => {
          // Update the old data to match the new
          commit("editWebsiteSettings", payload);
          dispatch("showSnackbar", { text: "Website Settings Updated Successfully!", color: "success", timeout: 2500 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateWebsitePriceListMappings({ commit, dispatch, getters }, payload) {
      Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites/${getters.getWebsite.website_id}/pricelists`, payload)
        .then(() => {
          // Update the old data to match the new
          commit("editWebsitePriceLists");
          dispatch("showSnackbar", { text: "Website Price List Mappings Updated Successfully!", color: "success", timeout: 2500 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteWebsite({ commit, dispatch }, payload) {
      dispatch("performingUpdate", true);
      Axios.delete(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website_id}`)
        .then(() => {
          commit("removeWebsite", payload.website_id);
        })
        .then(() => {
          router.push({ path: '/sites'}).catch(() => {});
          dispatch("performingUpdate", false);
          dispatch("showSnackbar", { text: `${payload.website_name} Deleted Successfully!`, color: "success", timeout: 2500 });
        })
        .catch(err => {
          console.log(err);
        });
    },
    discardWebsiteChanges({ commit, dispatch }) {
      commit("discardWebsiteChanges");
      dispatch("showSnackbar", {text: "Changes Discarded Successfully!", color: "success", timeout: 5000});
    },
    async addOauthToWebsite(_, payload) {
      await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website_id}/oauth`, {'code': payload.code})
      .catch(err => {
        console.log(err);
      });
    },
    async convertFromOauthToBasic({ dispatch }, payload) {
      dispatch("performingUpdate", true);
      await Axios.put(`${process.env.VUE_APP_API_BASE_URL}/websites/${payload.website_id}/converttobasicauth`, payload)
      .catch(err => {
        console.log(err);
      });
      dispatch("performingUpdate", false);
    }
  },
  getters: {
    getWebsites: state => state.websites,
    getWebsite: state => state.newData.website,
  },
};
