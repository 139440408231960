<template>
  <v-snackbar
    v-model="show"
    :color="snackbar.color"
    bottom
    right
    :timeout="snackbar.timeout"
    :style="calculateHeight"
  >
    <v-progress-circular v-if="snackbar.loader" indeterminate :width="2" :size="20"></v-progress-circular>
    {{ snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="removeSnackbar">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  props: {
    snackbar: Object,
    index: Number,
  },
  computed: {
    calculateHeight() {
      return `margin-bottom: ${this.index * 60}px`;
    },
  },
  methods: {
    removeSnackbar() {
      this.$store.commit("removeSnackbar", this.snackbar.id);
    }
  }
};
</script>
